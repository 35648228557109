/* You can add global styles to this file, and also import other style files */
@import "@design-factory/design-factory/design-factory";

.btn-criteria-lg {
    border-radius: 25px;
    width: 12.6rem;
}

.btn-criteria {
    border-radius: 25px;
    width: 8rem;
}

.modal-backdrop.show {
  opacity: 0.5;
}
